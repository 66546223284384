import React from "react"
import styled from "styled-components"

const EmbedItem = ({ title, media }) => {
  return (
    <Container>
      <h2>{title.text}</h2>
      <Iframe
        title="video"
        src={media.url}
        frameBorder="0"
        allowFullScreen
        seamless
      />
    </Container>
  )
}

export default EmbedItem

const Container = styled.div`
  padding: 40px;
  @media (max-width: 880px) {
    padding: 16px;
  }
`

const Iframe = styled.iframe`
  width: 100%;
  height: 800px;
  padding: 0 0 80px 0;
  @media (max-width: 1080px) {
    height: 600px;
  }
  @media (max-width: 880px) {
    height: 400px;
  }
`
