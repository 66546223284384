import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { withPreview } from "gatsby-source-prismic"

import Layout from "../layout/layout"
import SEO from "../seo"
import PageHeader from "../pageHeader"
import EmbedItem from "../emptyPage/embedItem"
import LiveCams from "../emptyPage/liveCams"

const EmptyPage = ({ data }) => {
  const { uid, data: pageData } = data.prismicEmptyPage
  const { title, tagline, text } = pageData.body.find(
    slice => slice.slice_type === "header"
  ).primary
  const isCamPage =
    uid.includes("cam") || uid.includes("web") || uid.includes("live")
  return (
    <Layout>
      <SEO title={uid} />
      <PageHeader title={title} tagline={tagline} text={text} />
      {isCamPage ? (
        <LiveCams embeds={pageData.embeds} />
      ) : (
        pageData.embeds.map((embed, idx) => {
          return <EmbedItem key={idx} {...embed} />
        })
      )}
    </Layout>
  )
}

export default withPreview(EmptyPage)

const Container = styled.div`
  padding: 0 40px;
`
const Iframe = styled.iframe`
  width: 100%;
  height: 800px;
  padding: 0 0 80px 0;
  @media (max-width: 1080px) {
    height: 600px;
  }
  @media (max-width: 880px) {
    height: 400px;
  }
`

export const query = graphql`
  query ($uid: String!) {
    prismicEmptyPage(uid: { eq: $uid }) {
      uid
      _previewable
      data {
        body {
          ... on PrismicEmptyPageBodyHeader {
            id
            slice_type
            primary {
              title {
                html
              }
              text {
                text
              }
              tagline {
                text
              }
            }
          }
        }
        embeds {
          title {
            text
          }
          media {
            type
            url
          }
        }
      }
    }
  }
`
