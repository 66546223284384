import React from "react"
import styled from "styled-components"

const getCamOne = () => {
  let _html = ""
  return (_html =
    '<div class="earthcam-embed-container" style="position:relative;padding-bottom:56.25%;height:0px;overflow:auto;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;">' +
    '<iframe title="EarthCam Video Player Embed" alt="EarthCam Video Player Embed"  autoplay allowfullscreen allow-scripts allow-same-origin id="iframe" width="800" scrolling="no" height="450" frameborder="0" marginwidth="0" marginheight="0"' +
    'style="position:absolute;top:0;left:0;width:10px;min-width:100%;*width:100%;height:100%;"' +
    'src="//www.earthcam.com/js/video/embed.php?type=h264&amp;vid=playalinda1.flv&amp;w=auto&amp;company=playalinda&amp;timezone=America/Aruba&amp;requested_version=current"></iframe>' +
    "</div>")
}
const getCamTwo = () => {
  let _html = ""
  return (_html =
    '<div class="earthcam-embed-container" style="position:relative;padding-bottom:56.25%;height:37px;overflow:auto;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box;">' +
    '<iframe title="EarthCam Video Player Embed" alt="EarthCam Video Player Embed"  autoplay allowfullscreen allow-scripts allow-same-origin id="iframe" width="800" scrolling="no" height="450" frameborder="0" marginwidth="0" marginheight="0"' +
    'style="position:absolute;top:0;left:0;width:10px;min-width:100%;*width:100%;height:100%;"' +
    'src="//www.earthcam.com/js/video/embed.php?type=h264&amp;vid=playalinda2.flv&amp;w=auto&amp;company=PlayaLinda&amp;timezone=America/Aruba&amp;metar=TNCA&amp;ecn=1&amp;requested_version=current"></iframe>' +
    "</div>")
}

const LiveCams = ({ embeds }) => {
  return (
    <>
      <CamContainer>
        <h2>{embeds[0].title.text}</h2>
        <Cam dangerouslySetInnerHTML={{ __html: getCamOne() }} />
      </CamContainer>
      <CamContainer>
        <h2>{embeds[1].title.text}</h2>
        <Cam dangerouslySetInnerHTML={{ __html: getCamTwo() }} />
      </CamContainer>
    </>
  )
}

export default LiveCams

const CamContainer = styled.div`
  padding: 0 24px 80px 24px;
`

const Cam = styled.div``
